import React, { useContext } from "react";
import { PageProps } from "gatsby";
import { Layout } from "../components/Layout";
import { useTranslation } from "react-i18next";
import { Container, OverlayTrigger, Popover, Tooltip, Overlay } from "react-bootstrap";
import { Map } from "../components/map/Map";
import { Select } from "../components/select/Select";
import { citiesList, Endpoint } from "../data/citiesDropDownData";
import { JsonBodyView } from "../components/JsonBodyView";
import parse from 'html-react-parser';
import style from './rainfall-data.module.scss';
import { DataContext } from "../states/dataContext";
import { MapContext } from "../states/mapContext";
import { convertPointRainfallRateUrl } from "../functions/api";
import { DrawRectangleMode } from "react-map-gl-draw";

export default function RainfallDataPage(props: PageProps) {

    const { t } = useTranslation();
    const { activeCity, activeEndpoint, selectEndpoint, resetAllData } = useContext(DataContext);
    const { markedPointLat, markedPointLong, dateRange, getDefaultTimeRange, resetEditorFeatures } = useContext(MapContext);
    const { toggleEditorMode } = useContext(MapContext);

    const parseStringToHtml = (str: string) => {
        return parse(str)
    };

    const onChangeEndpoint = (endpoint: Endpoint) => {
        resetAllData()
        selectEndpoint(endpoint);
        resetEditorFeatures()

        if (endpoint.type === "PointRainfallRate") {
            toggleEditorMode(undefined, true);
            getDefaultTimeRange("PointRainfallRate")
        } else {
            toggleEditorMode(undefined, true);
            getDefaultTimeRange("AreaRainfallRate")
        };
    };

    const convertApiUrl = () => {
        if (activeEndpoint?.type === "AreaRainfallRate") {
            return activeEndpoint?.apiUrl
        } else {
            return convertPointRainfallRateUrl(markedPointLong, markedPointLat, dateRange.startDate.unix(), dateRange.endDate.unix());
        };
    };

    if (!activeEndpoint) return null;

    return (
        <Layout pageProps={props}>
            <Container className={style.rainfallContainer}>
                <h2 className={style.rainfallDataTitle} >{t("Map container title")}</h2>
                <Select
                    onSelect={(endpoint: Endpoint) => onChangeEndpoint(endpoint)}
                    selectItemsList={activeCity.endpoints}
                    currentSelectedItem={activeEndpoint}
                    type="dataType"
                />
                <p className={style.aboutApiParagraph}>
                    {parseStringToHtml(t(activeEndpoint.text))}
                    <br /><br />
                    {t(activeEndpoint.desc)}
                </p>
                <ul className={style.aboutApiParagraphSteps}>
                    <li>{parseStringToHtml(t(activeEndpoint.step1))}</li>
                    <li>{parseStringToHtml(t(activeEndpoint.step2))}</li>
                    <li>{parseStringToHtml(t(activeEndpoint.step3))}</li>
                    <li>{parseStringToHtml(t(activeEndpoint.step4))}</li>
                    <li>{parseStringToHtml(t(activeEndpoint.step5))}</li>
                    <li>{parseStringToHtml(t(activeEndpoint.step6))}</li>
                    <li>{parseStringToHtml(t(activeEndpoint.step7))}</li>
                </ul>

                <div className={style.mapContainer}>
                    <div className={style.map}>
                        <Map />
                    </div>
                    <JsonBodyView url={convertApiUrl()} />
                </div>
            </Container>
        </Layout>
    );
}