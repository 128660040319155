import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { DataContext } from "../states/dataContext";
import { MapContext } from "../states/mapContext";
import { getJsonBody } from "../functions/api";
import { useLocalization } from "gatsby-theme-i18n";
import moment from 'moment';
import style from './JsonBodyView.module.scss'

export type JsonBodyViewProps = {
    url: string
};

export const JsonBodyView: FC<JsonBodyViewProps> = ({ url }: JsonBodyViewProps) => {

    // TODO
    const api = url;

    const { t } = useTranslation();
    const { isJsonBodyVisible, activeEndpoint } = useContext(DataContext);
    const { dateRange, point1, point2 } = useContext(MapContext);

    const renderJsonBody = () => {

        let duration = moment.duration(dateRange.endDate.diff(dateRange.startDate));
        let diffMinutes = duration.asMinutes();

        let endTime = diffMinutes >= 5 ? moment().startOf("minutes").add(-5, "minutes").unix() : dateRange.endDate.unix();

        let body = getJsonBody({
            endDate: endTime,
            startDate: dateRange.startDate.unix(),
            point1: point1,
            point2: point2
        })
        return body;
    };

    if (!isJsonBodyVisible) return null

    return (
        <div>
            <div className={`d-flex align-items-start ${style.container}`}>
                <p style={{ width: 110 }}>{t("Api link label")}: </p>
                <div className={style.codeHighlightApiLink} style={{maxWidth: 793, marginBottom: 10}}>
                    <code>{api}</code>
                </div>
            </div>

            <div className={`d-flex align-items-start ${style.container}`}>
                <p className={style.methodParagraph} style={{ width: 113 }} >Method:</p>
                <div className={style.codeHighlightApiLink}>
                    <code>{activeEndpoint?.method}</code>
                </div>
            </div>

            {
                activeEndpoint?.type === "AreaRainfallRate" &&
                <div className={`d-flex align-items-start ${style.downloadDataContainerBody}`} >
                    <p style={{ width: 109 }}>{t("body label")}: </p>
                    <div className={style.codeWrapper}>
                        <pre>
                            <code>
                                {renderJsonBody()}
                            </code>
                        </pre>
                    </div>
                </div>
            }

        </div>
    );
};